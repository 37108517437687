<template>
  <div>
    <v-container
      fluid
      class="pt-0 pb-0"
    >
      <v-row class="text-left">
        <v-col
          cols="12"
          class="text-left pt-0 pb-0"
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                class="red ml-0 mt-3"
                dark
                v-on="on"
                :disabled="!$can('agent', 'removeAdvert')"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  mdi-delete
                </v-icon>
                Удалить
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="setDeleteType('unverified')"
              >
                <v-list-item-title>Неподтвержденные</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="$route.name === 'advert'"
                @click="setDeleteType('selected')"
              >
                <v-list-item-title>Выделенные</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-dialog
            :value="isOpen"
            max-width="590"
          >
            <v-card
              outlined
              class="pa-5"
            >
              <v-card-title class="headline">
                Подтверждение удаления записей
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  @click="isOpen = false"
                >
                  Отменить
                </v-btn>
                <v-btn
                  class="primary"
                  small
                  @click="handleDelete"
                  :loading="loading"
                >
                  Удалить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as Config from '@/config'

export default {
  name: 'DeleteDialog',
  props: {
    selectedItems: Array
  },
  data: () => ({
    loading: false,
    isOpen: false,
    deleteType: null
  }),
  methods: {
    setDeleteType (type) {
      this.deleteType = type
      this.isOpen = true
    },
    async handleDelete () {
      this.loading = true
      if (this.deleteType === 'selected') {
        const selected = this.selectedItems.map(item => item.id)
        await this.$store.dispatch('table/deleteGroup', {
          url: '/advert/detail?id=' + JSON.stringify(selected)
        })
      } else if (this.deleteType === 'unverified') {
        await this.$store.dispatch('table/deleteGroup', {
          url: '/' + this.$route.name + '?source=' + Config.advertSources.donRio
        })
      }
      this.loading = false
      this.isOpen = false
      this.$emit('update')
    }
  }
}
</script>

<style scoped>

</style>
