export default [
  {
    text: 'Фото',
    align: 'left',
    sortable: true,
    value: 'photoCount',
    width: '40px'
  },
  {
    text: 'Дом',
    align: 'left',
    sortable: true,
    value: 'is_ready',
    width: '40px'
  },
  {
    text: 'Тип жилья',
    align: 'left',
    sortable: true,
    value: 'building_type',
    width: '70px'
  },
  {
    text: 'Район О',
    align: 'left',
    sortable: true,
    value: 'district_official',
    width: '130px'
  },
  {
    text: 'Район Н',
    align: 'left',
    sortable: true,
    value: 'district_custom',
    width: '130px'
  },
  {
    text: 'Подрайон',
    align: 'left',
    sortable: true,
    value: 'subdistrict',
    width: '100px'
  },
  {
    text: 'Адрес',
    align: 'left',
    sortable: true,
    value: 'address',
    width: '120px'
  },
  {
    text: 'Э-ть',
    align: 'left',
    sortable: true,
    value: 'total_floors',
    width: '20px'
  },
  {
    text: 'Мат. стен',
    align: 'left',
    sortable: true,
    value: 'building_material',
    width: '90px'
  },
  {
    text: 'Год',
    align: 'left',
    sortable: true,
    value: 'build_year',
    width: '25px'
  },
  {
    text: 'ЖК',
    align: 'left',
    sortable: true,
    value: 'apartment_complex',
    width: '100px'
  },
  {
    text: 'Застройщик',
    align: 'left',
    sortable: true,
    value: 'builder',
    width: '80px'
  },
  {
    text: 'кво п-в',
    align: 'left',
    sortable: true,
    value: 'front_doors_count',
    width: '20px'
  },
  {
    text: 'к-во кв-р',
    align: 'left',
    sortable: true,
    value: 'total_apartments',
    width: '20px'
  },
  {
    text: 'Каталог',
    align: 'left',
    sortable: true,
    value: 'id',
    width: '50px'
  },
  {
    text: 'Создано',
    align: 'left',
    sortable: true,
    value: 'created_at',
    width: '80px'
  }
]
