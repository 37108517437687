<template>
  <v-container fluid>
    <v-row class="text-left">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :options="options"
          @update:options="$emit('input', $event)"
          :server-items-length="itemsLength"
          :loading="loading"
          class="elevation-1"
          multi-sort
          :footer-props="{itemsPerPageOptions:  [20,100,300]}"
        >
          <template v-slot:item="{ item }">
            <tr
              :class="classByStatus(item)"
              @click="detail(item.id)"
            >
              <td>{{item.buildingImages.length ? 'Да' : 'Нет'}}</td>
              <td>{{item.is_ready ? 'Сдан' : 'Не сдан'}}</td>
              <td>{{shortBuildingType(item.building_type)}}</td>
              <td>{{formatValue('building.district_official', item.district_official)}}</td>
              <td>{{formatValue('building.district_custom', item.district_custom)}}</td>
              <td>{{formatValue('building.subdistrict', item.subdistrict)}}</td>
              <td>
                <div
                  v-for="(address, index) in item.buildingAddresses"
                  :key="index"
                >
                  {{address.street.name}} {{address.building_number}}
                </div>
              </td>
              <td>{{item.total_floors}}</td>
              <td>{{shortBuildingMaterial(item.building_material)}}</td>
              <td>{{item.build_year}}</td>
              <td>{{formatValue('building.apartment_complex', item.apartment_complex)}}</td>
              <td>{{formatValue('building.builder', item.builder)}}</td>
              <td>{{item.front_doors_count}}</td>
              <td>{{item.total_apartments}}</td>
              <td>{{item.id}}</td>
              <td>{{item.created_at ? timestampToDatetime(item.created_at) : ''}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import headers from './header'
import formatters from '@/components/mixins/formatters'
import table from '@/components/mixins/table'

export default {
  mixins: [formatters, table],
  name: 'Table',
  data: () => ({
    headers: headers
  }),
  methods: {
    classByStatus (item) {
      let className = ''
      if (this.opened.includes(item.id)) {
        className += ' selected-item'
      }
      return className
    },
    shortBuildingType (val) {
      let type = this.formatValue('building.building_type', val)
      return type ? type.slice(0, 7) : type
    },
    shortBuildingMaterial (val) {
      let type = this.formatValue('building.building_material', val)
      return type ? type.slice(0, 4) : type
    }
  }
}
</script>

<style scoped>

</style>
