<template>
  <div class="d-flex">
    <DistrictSelect
      v-if="!isServiceDataLoading"
      class="district-filter-block"
      :districtCustom.sync="value.districtCustom"
      :districtOfficial.sync="value.districtOfficial"
    />
    <v-container
      fluid
      class="white elevation-0 all-filter-block"
    >
      <v-row>
        <v-col cols="6">
          <DateRange
            v-model="value.touchDate"
            title="Диапазон дат касания"
          />
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="value.flatType"
                :items="flatTypeList"
                label="Тип жилья"
                item-value="id"
                item-text="value"
                multiple
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="value.kitchenType"
                :items="planningList"
                :label="$t('advert.kitchen_type')"
                item-value="value"
                item-text="value"
                return-object
                multiple
                :disabled="isRoomsFilterActive && !(value.rooms[0] || value.rooms[1])"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <div class="mr-0">
                <v-select
                  v-model="value.buildingMaterial"
                  :items="buildingMaterialItemsWithEmptyAll"
                  label="Материал стен"
                  item-value="id"
                  item-text="value"
                />
              </div>
            </v-col>
            <v-col cols="4">
              <div class="mr-1">
                <v-select
                  v-model="value.flatStatus"
                  :items="flatStatusItemsWithEmpty"
                  label="Состояние"
                  item-value="id"
                  item-text="value"
                  multiple
                ></v-select>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <!--поиск по адресу-->
            <v-col cols="7">
              <Autocomplete
                v-model="value.streetFilter"
                item-value="streetId"
                item-text="name"
                placeholder="Улица"
                url="/street/autocomplete"
                :chars-to-search="2"
              />
            </v-col>
            <v-col cols="5">
              <div class="flex-grow-0 mr-2">
                <v-autocomplete
                  v-model="value.buildingNumberFilter"
                  :items="buildingNumbersWithEmpty"
                  label="Дом"
                  item-value="id"
                  item-text="building_number"
                  :loading="buildingLoading"
                  :disabled="!value.streetFilter"
                  @focus="fillBuildingNumbers"
                  return-object
                ></v-autocomplete>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="mr-0">
                <v-select
                  v-model="value.builder"
                  :items="builderItemsWithEmpty"
                  label="Застройщик"
                  item-value="id"
                  item-text="value"
                  multiple
                />
              </div>
            </v-col>
            <v-col cols="6">
              <div class="mr-0">
                <v-select
                  v-model="value.apartmentComplex"
                  :items="apartmentComplexItemsWithEmpty"
                  label="ЖК"
                  item-value="id"
                  item-text="value"
                  multiple
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="12">
              <Slider
                v-model="value.rooms"
                :title="`Количество комнат от ${filterRanges.rooms[0]} до ${filterRanges.rooms[1]}`"
                :range="filterRanges.rooms"
                @changeSlider="onChangeSlider"
              />
            </v-col>
            <v-col cols="12">
              <Slider
                v-model="value.buildingYear"
                :title="`Год постройки от ${filterRanges.buildingYear[0]} до ${filterRanges.buildingYear[1]}`"
                :range="filterRanges.buildingYear"
              />
            </v-col>
            <v-col cols="12">
              <Slider
                v-model="value.floors"
                :title="`Этажность<br>от ${filterRanges.floors[0]} до ${filterRanges.floors[1]}`"
                :range="filterRanges.floors"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="12">
              <Slider
                v-model="value.floor"
                :title="`Этаж от <br>${filterRanges.floor[0]} до ${filterRanges.floor[1]}`"
                :range="filterRanges.floor"
              />
            </v-col>
            <v-col cols="12">
              <Slider
                v-model="value.areaCommon"
                :title="`Общая площадь <br>от ${filterRanges.areaCommon[0]} до ${filterRanges.areaCommon[1]}`"
                :range="filterRanges.areaCommon"
              />
            </v-col>
            <v-col cols="12">
              <Slider
                v-model="value.price"
                :title="`Цена от ${filterRanges.price[0]} (тыс.рублей) до ${filterRanges.price[1]} (тыс.рублей)`"
                :range="filterRanges.price"
                input-style="width: 100px"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import { cleanClone, deepEqual } from '@/components/utils/common'
import Slider from '@/components/views/admin/advert/Common/Controls/Slider'
import DateRange from '@/components/views/admin/advert/Common/Controls/DateRange'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import DistrictSelect from '@/components/views/admin/advert/Common/Filters/DistrictSelect'
import planningTypesByRooms from '@/components/views/admin/advert/Common/Filters/planningTypesByRooms'

//todo: рефактор кода, объединить с AdvertDialogFilter и сделать DialogFilter
export default {
  mixins: [serviceDataComputeds, planningTypesByRooms],
  name: 'Filters',
  components: { DistrictSelect, DateRange, Slider, Autocomplete },
  props: {
    value: Object,
    active: Boolean,
    loading: Boolean
  },
  data () {
    return {
      buildingNumberList: [],
      buildingLoading: false,
      isRoomsFilterActive: false,
      filterRanges: {
        rooms: [0, 0],
        areaCommon: [0, 0],
        floor: [0, 0],
        floors: [0, 0],
        price: [0, 0],
        buildingYear: [0, 0]
      }
    }
  },
  watch: {
    'value.streetFilter': {
      handler (newVal, oldVal) {
        if (oldVal !== undefined && !deepEqual(newVal, oldVal)) {
          this.buildingNumberFilter = null
          this.buildingNumberList = []
        }
      },
      deep: true
    }
  },
  methods: {
    async loadFilters () {
      if (Object.entries(this.value).length) {
        const filters = Object.assign({}, this.value)
        for (const prop in filters) {
          if (filters.hasOwnProperty(prop) && this.hasOwnProperty(prop)) {
            this.filterLocalValues[prop] = filters[prop]
            if (prop === 'streetFilter') {
              await this.fillBuildingNumbers()
            }
          }
        }
      }
      return true
    },
    async fillFilterDefaults () {
      const response = await this.$store.dispatch('table/getFilterDefaults', { url: '/advert/filter-defaults' })
      if (response) {
        this.filterRanges = cleanClone(response)
      }
      return true
    },
    async fillBuildingNumbers () {
      if (this.value.streetFilter) {
        this.buildingLoading = true
        const response = await this.$store.dispatch('table/getServerData', { url: '/address/building-numbers?streetId=' + this.value.streetFilter.id })
        if (response) {
          this.buildingNumberList = response
        }
        this.buildingLoading = false
      }
    },
    onChangeSlider (e) {
      this.isRoomsFilterActive = e
      this.value.kitchenType = []
    }
  },
  computed: {
    isServiceDataLoading () {
      return this.$store.getters['serviceData/isLoading']
    },
    flatTypeList () {
      let result = this.flatTypeItems.filter((item) => item.id !== 7)
      return result
    }
  },
  //note: асинхронный метод вызываем последним, т.к. во время его выполнения срабатывает watch
  async created () {
    await this.loadFilters()
    return this.fillFilterDefaults()
  }
}
</script>

<style scoped>
  .district-filter-block {
    width: 300px;
    position: relative;
    height: 65vh;
    overflow-x: hidden;
    overflow-y: visible;
  }
  .all-filter-block {
    position: relative;
    height: 65vh;
    overflow-x: hidden;
    overflow-y: visible;
  }
</style>
