const empty = {
  id: null,
  value: 'Не указано'
}

const roomPlanningLists = {
  1: [
    { id: 1839, rooms: 1, value: 'Гостинка' },
    { id: 1785, rooms: 1, value: 'Студия' },
    { id: 1786, rooms: 1, value: 'Однокомнатная' }
  ],
  2: [
    { id: 1839, rooms: 2, value: '2к Гостинка' },
    { id: 1788, rooms: 2, value: 'Евро-2' },
    { id: 1787, rooms: 2, value: '2к Смежная' },
    { id: 1786, rooms: 2, value: '2к Раздельная' }
  ],
  3: [
    { id: 1788, rooms: 3, value: 'Евро-3' },
    { id: 1787, rooms: 3, value: '3к Смежная' },
    { id: 1786, rooms: 3, value: '3к Раздельная' }
  ],
  4: [
    { id: 1788, rooms: 4, value: 'Евро-4' },
    { id: 1787, rooms: 4, value: '4к Смежная' },
    { id: 1786, rooms: 4, value: '4к Раздельная' }
  ],
  5: [
    { id: 1788, rooms: 5, value: 'Евро-5' },
    { id: 1787, rooms: 5, value: '5к Смежная' },
    { id: 1786, rooms: 5, value: '5к Раздельная' }
  ],
  6: [
    { id: 1788, rooms: 6, value: 'Евро-6' },
    { id: 1787, rooms: 6, value: '6к Смежная' },
    { id: 1786, rooms: 6, value: '6к Раздельная' }
  ]
}

export default {
  computed: {
    planningList () {
      const { rooms } = this.value
      const { rooms: roomRange } = this.filterRanges

      if (!rooms || (!rooms[0] && !rooms[1])) {
        return this.kitchenTypeItemsWithEmpty
      }

      let [min, max] = roomRange
      if (rooms[0]) min = rooms[0]
      if (rooms[1]) max = rooms[1]

      const combinedList = Object.keys(roomPlanningLists)
        .filter(key => key >= min && key <= max)
        .reduce((list, key) => list.concat(roomPlanningLists[key]), [empty])

      const specialItems = this.kitchenTypeItemsWithEmpty.filter(obj => obj.id === 1789 || obj.id === 1790)
      return combinedList.concat(specialItems)
    }
  }
}
